import React from 'react'
import { HelmetFunc } from 'components/PageMetaData'
import { RunningLine } from 'components/RunningLine'
import { useTranslation } from 'react-i18next'
import { Layout } from 'layout/Layout'
import { DefaultFormBlock } from 'blocks/DefaultFormBlock'
import styled from 'styled-components'
import { backgroundColors, colors } from 'styles/colors'
import { mobileAfterBorder } from 'styles/mobileAfterBorder'
import { displayWidth } from 'styles/width'
import { Container } from 'components/Container'
import { IconListQuestion } from 'components/IconListQuestion'
import { graphql } from 'gatsby'
import { getDataByLanguage } from 'utils/getDataByLanguage'
import { ExampleOfProjectArch } from 'blocks/ExampleOfProjectArch'
import { IItem } from 'types/interfaces'
import { LeftSidebar, RightSidebar } from 'components/SideBar'

const pageMetadata = {
    uk: {
        title: 'Зразок архітектурного проекту приватного будинку',
        description:
            'Проект містить плани поверхів, фундаменту, міжповерхових перекриттів, віконних та дверних отворів, даху, крокв, покрівлі, фасадів, розрізи',
    },
    ru: {
        title: 'Образец архитектурного проекта частного дома',
        description:
            'Проект содержит планы этажей, фундамента, межэтажных перекрытий, оконных и дверных проемов, крыши, стропил, кровли, фасадов, разрезы',
    },
    en: {
        title: 'An example of an architectural project of a private house',
        description:
            'The project contains floor plans, foundations, floors, window and door openings, roofs, rafters, roofing, facades, sections',
    },
}

const ProjectStructureWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: ${backgroundColors.project};
    position: relative;
    @media (min-width: ${displayWidth.tablet}) {
        border-bottom: 1px solid ${colors.dark};
    }
    ${mobileAfterBorder}
`

const IconListStyled = styled(IconListQuestion)`
    background-color: ${backgroundColors.contact};
    border-bottom: 1px solid ${colors.dark};
    h2 {
        font-size: 30px;
        line-height: 35px;
    }
    @media (min-width: ${displayWidth.tablet}) {
        border-bottom: none;
    }
`

const ExamplePage = ({
    data,
}: {
    data: {
        allHouseStructureQuestionYaml: { items: IItem }
        allExampleArchitectFaqYaml: { items: IItem }
    }
}) => {
    const { t, i18n } = useTranslation()

    const projectStructureData = getDataByLanguage(
        data.allHouseStructureQuestionYaml,
        i18n.language
    )
    const { itemsList } = projectStructureData

    const faqData = getDataByLanguage(
        data.allExampleArchitectFaqYaml,
        i18n.language
    )
    return (
        <Layout>
            <HelmetFunc data={pageMetadata} />
            <ExampleOfProjectArch />
            <ProjectStructureWrapper>
                <LeftSidebar background={backgroundColors.contact} />
                <Container columns={'1fr'} tabletColumns={'1fr'}>
                    <IconListStyled
                        items={faqData.items}
                        fill={colors.accentText}
                        background={backgroundColors.promotion}
                    />
                </Container>
                <RightSidebar />
            </ProjectStructureWrapper>
            <RunningLine>{t('designProject99')}</RunningLine>
            <ProjectStructureWrapper>
                <LeftSidebar background={backgroundColors.contact} />
                <Container columns={'1fr'} tabletColumns={'1fr'}>
                    <IconListStyled
                        items={itemsList}
                        fill={colors.accentText}
                        background={backgroundColors.promotion}
                    />
                </Container>
                <RightSidebar />
            </ProjectStructureWrapper>
            <DefaultFormBlock
                withPhoneMobile
                tracking={{
                    conversionType: 'FormExample',
                    eventCategory: 'FormExample',
                }}
            />
        </Layout>
    )
}

export default ExamplePage

export const query = graphql`
    query {
        allExampleArchitectFaqYaml {
            edges {
                node {
                    items {
                        question
                        answer
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
        allHouseStructureQuestionYaml {
            edges {
                node {
                    formatedTitle
                    itemsNew {
                        question
                        answer
                        svg
                    }
                    itemsList {
                        question
                        answer
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
    }
`
